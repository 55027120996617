import React, { useContext } from 'react';
import { fragments, getFragments } from '../../../../get_server_query';
import {
  format,
  renderServerComponents,
} from '../../../components/cms-modules';
import * as styles from './styles.module.scss';
import Search from '../../../components/ui/filterSearch';
import ResultsList from '../../../components/ui/resultsList';
import {
  FILTERS,
  useContentfulArticleFilters,
} from '../../../hooks/useContentfulArticleFilters';
import { getCopy, withMicrocopy } from '../../../helpers/copy';
import ArticlesTeaserItem from '../../../components/cms-modules/editorialTeaser/components/articlesTeaserItem';
import Layout from '../../../modules/layout';
import Headline from '../../../components/ui/headline';
import { MicrocopyContext } from '../../../context/microcopy.context';
import { navigate } from 'gatsby';
import { resolveReferences } from '../../../helpers/data';

const RESULTS_PER_PAGE = 10;

const getArticleSearchableContent = (article) => {
  let textContent = [];

  const WHITELIST = [
    'content',
    'headline',
    'subline',
    'label',
    'raw',
    'body',
    'text',
    'keywords',
    'articleContentElements',
    'fields',
    'value',
    'content2ColumnImageTextItems',
  ];

  const traverse = (obj) => {
    if (typeof obj === 'string') {
      textContent.push(obj);
    } else if (Array.isArray(obj)) {
      obj.forEach((item) => traverse(item));
    } else if (typeof obj === 'object' && obj !== null) {
      Object.entries(obj).forEach(([key, value]) => {
        if (WHITELIST.includes(key)) {
          traverse(value);
        }
      });
    }
  };

  traverse(article);

  return textContent.join().toLowerCase();
};

const EditorialFilter = ({
  serverData: { articles, page, total, locale, queryFilters },
}) => {
  const { title, seoDescription, content } = page;

  const contentWithoutFilterlist = content.filter(
    (el) => el.__typename !== 'ContentEditorialTeaser'
  );

  const onSubmit = (searchTerm) => {
    const params = new URLSearchParams(location.search);
    params.set('find', searchTerm.split('=').pop());
    params.set('page', '1');
    navigate(`?${params.toString()}`, {
      replace: true,
    });
  };

  const resetSearchTerm = () => {
    const params = new URLSearchParams(location.search);
    params.set('find', '');
    navigate(`?${params.toString()}`, {
      replace: true,
    });
  };

  const TranslatedContent = withMicrocopy(
    () => {
      const microcopy = useContext(MicrocopyContext);

      return (
        <Layout title={title} description={seoDescription}>
          {contentWithoutFilterlist &&
            renderServerComponents(contentWithoutFilterlist, locale)}
          {
            <Headline
              level="h2"
              type="h1"
              text={getCopy('editorialFilter.headline', microcopy)}
              className={styles.headline}
            />
          }
          <Search
            onSubmit={onSubmit}
            resetSearchTerm={resetSearchTerm}
            initSearchTerm={queryFilters.find}
            placeholder={getCopy(
              'placeholder.whatDoYouWantToExplore',
              microcopy
            )}
          />
          <ResultsList
            queryFilters={queryFilters}
            whiteBackground
            borderInHeader
            filtersHook={useContentfulArticleFilters}
            itemRenderer={ArticlesTeaserItem}
            countLabelKey="results.articles"
            initialResults={articles}
            resultsContainerClassName={styles.container}
            hideFilterOnLoad={false}
            resultsPerPage={RESULTS_PER_PAGE}
            totalResults={total}
            usePagination
          />
        </Layout>
      );
    },

    locale
  );

  return <TranslatedContent />;
};

export async function getServerData(ctx) {
  const locale = ctx.pageContext.locale || 'en';
  const gqlUrl = `${process.env.GATSBY_CONTENTFUL_GRAPHQL_URL}/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/${process.env.GATSBY_CONTENTFUL_ENVIRONMENT}`;
  const authHeader = `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`;
  const queryParams = new URLSearchParams(ctx.query);
  const currentPage = queryParams.get('page')
    ? parseInt(queryParams.get('page'))
    : 1;

  // GET PAGE ENTRY & ITS CONTENT
  const getPageContentTypes = (locale = 'en') => `
  {
    pageCollection(where: {slug: "if-magazine/editorial-filter"}, limit: 1, locale: "${locale}") {
      items {
        content: contentCollection(limit: 10) {
          items {
            __typename
          }
        }

      }
    }
  }
  `;

  const typesResponse = await fetch(gqlUrl, {
    method: 'POST',
    body: JSON.stringify({
      operationName: null,
      query: getPageContentTypes(locale),
      variables: {},
    }),
    headers: {
      Authorization: authHeader,
      'Content-Type': 'application/json',
    },
  });
  const typesJson = await typesResponse.json();
  const includedTypes =
    typesJson.data?.pageCollection?.items[0]?.content?.items.map(
      (el) => el.__typename
    );

  const getPage = (locale = 'en', includedTypes = []) => {
    return `
  ${getFragments([...includedTypes]).replace(/(\W)\s*/g, '$1')}
  query {
    pageCollection(where: {slug: "if-magazine/editorial-filter"}, limit: 1, locale: "${locale}") {
      items {
        title
        slug
        seoDescription
        content: contentCollection(limit: 15) {
          items {
            ${Array.from(new Set(includedTypes))
              .map((type) => `...${fragments[type]?.name}`)
              .join(' ')}
          }
        }
        hasWhiteNavigation
        metaNaviWhite
        footerColor
        external
        hideHeaderAndFooter
        filterlist
        __typename
      }
    }
  }
  `;
  };

  const query = getPage(locale, includedTypes);

  const res = await fetch(gqlUrl, {
    method: 'POST',
    body: JSON.stringify({
      operationName: null,
      query,
      variables: {},
    }),
    headers: {
      Authorization: authHeader,
      'Content-Type': 'application/json',
    },
  });

  const json = await res.json();

  const page = format(json.data.pageCollection.items[0]);
  // END OF GET PAGE ENTRY & ITS CONTENT

  // GET ARTICLES
  const currentYear = new Date().getFullYear();

  const dates = [];
  switch (queryParams.get('yearId')) {
    case '0':
      dates.push(currentYear);
      break;
    case '1':
      dates.push(...[currentYear - 1, currentYear]);
      break;
    case '2':
      dates.push(currentYear - 2);
      break;
    case '3':
      dates.push(currentYear - 4);
  }

  const dateFilter = dates
    .map(
      (year, i) =>
        `fields.date[${i === 0 ? 'gte' : 'lt'}]=${year}-01-01T00:00:00Z`
    )
    .join('&');

  const sortFilter =
    queryParams.get('sort') === 'asc'
      ? 'order=fields.date'
      : 'order=-fields.date';

  const categoryFilter =
    queryParams.get('articleCategoryId') != null
      ? `fields.articleCategories=${
          FILTERS.articleCategory.find(
            (el) => el.id === parseInt(queryParams.get('articleCategoryId'))
          ).name
        }`
      : '';

  const apiUrl = `https://cdn.contentful.com/spaces/${
    process.env.CONTENTFUL_SPACE_ID
  }/environments/${process.env.CONTENTFUL_ENVIRONMENT}/entries?access_token=${
    process.env.CONTENTFUL_ACCESS_TOKEN
  }&content_type=article&${[dateFilter, categoryFilter, sortFilter]
    .filter(Boolean)
    .join('&')}&locale=${locale}&include=3`;

  const response = await fetch(apiUrl);

  if (!response.ok) {
    throw new Error(
      `Fetching failed. Error: ${response.status} - ${response.statusText} ${response.url}`
    );
  }

  const data = await response.json();

  let resolvedArticles = resolveReferences(data);

  if (queryParams.get('find')) {
    resolvedArticles = resolvedArticles.filter((article) => {
      const searchableContent = getArticleSearchableContent(article);

      return searchableContent.includes(
        queryParams.get('find').toLocaleLowerCase()
      );
    });
  }

  const articles = resolvedArticles
    .map((article) => ({
      ...article,
      images: article.images?.map((image) => ({
        ...image,
        image: image.image.fields.file,
      })),
      subline: {
        subline: article.subline,
      },
    }))
    .slice(
      (currentPage - 1) * RESULTS_PER_PAGE,
      currentPage * RESULTS_PER_PAGE
    );

  return {
    status: 200,
    props: {
      articles,
      total: resolvedArticles.length,
      page,
      locale,
      queryFilters: {
        articleCategory: queryParams.get('articleCategoryId')
          ? Array.from(queryParams.get('articleCategoryId')).map(Number)
          : [],
        readingTime: queryParams.get('readingTimeId')
          ? Array.from(queryParams.get('readingTimeId')).map(Number)
          : [],
        time: queryParams.get('yearId')
          ? Array.from(queryParams.get('yearId')).map(Number)
          : [],
        sort: queryParams.get('sort') ? queryParams.get('sort') : 'desc',
        find: queryParams.get('find') ?? '',
        page: queryParams.get('page') ? parseInt(queryParams.get('page')) : 1,
      },
    },
  };
}

export default EditorialFilter;
