import { getYearFilters } from '../helpers/filters';

export const FILTERS = {
  sort: [
    {
      id: 'desc',
      name: 'Newest',
    },
    {
      id: 'asc',
      name: 'Oldest',
    },
  ],
  articleCategory: [
    { id: 1, name: 'Trends' },
    { id: 2, name: 'Sustainability' },
    { id: 3, name: 'AI' },
    { id: 4, name: 'Students' },
    { id: 5, name: 'Design Icons' },
    { id: 6, name: 'Social Impact' },
    { id: 7, name: 'Product Design' },
    { id: 8, name: 'Packaging Design' },
    { id: 9, name: 'Communication' },
    { id: 10, name: 'Interior Architecture' },
    { id: 11, name: 'Architecture' },
    { id: 12, name: 'Service Design' },
    { id: 13, name: 'UX/UI' },
  ],
  time: getYearFilters(),
};

export const useContentfulArticleFilters = (
  filters,
  items = [],
  _,
  usePagination,
  resultsPerPage,
  totalResults
) => {
  return {
    filterOrderByKey: ['sort', 'articleCategory', 'time'],
    visibleFilters: FILTERS,
    radioFiltersKeys: ['sort'],
    results: items,
    totalCount: totalResults || items.length,
  };
};
